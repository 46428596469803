import React from "react";
import { SaveButton, Toolbar, translate } from "react-admin";
import { compose } from "recompose";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { get } from "lodash";

import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckInOutButton from "./CheckInOutButton";

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing.unit * 1,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit,
  },
  // buttonSave: {
  //   backgroundColor: "yellow",
  // },

  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  icon: {
    fontSize: 18,
  },
});
const ViewLogsButtonComponent = ({
  classes,
  translate,
  addLeftPadding,
  onShowLogsRequest,
}) => (
  <Button
    icon={<VisibilityIcon />}
    className={addLeftPadding ? classes.button : null}
    onClick={onShowLogsRequest}
  >
    <VisibilityIcon className={classes.buttonIcon} />
    {translate("resources.explorer.actions.view_logs")}
  </Button>
);
const ViewLogsButton = compose(
  translate,
  withStyles(styles)
)(ViewLogsButtonComponent);

const FormToolbar = ({
  pristine,
  redirect,
  invalid,
  permissions,
  classes,
  onShowLogsRequest,
  ...props
}) => (
  <Toolbar {...props}>
    <CheckInOutButton />
    {(!get(props, "record.is_checked_out", false) ||
      get(props, "record.can_checkin")) && (
      <SaveButton
        classes={classes}
        label="resources.explorer.actions.save"
        disabled={
          pristine ||
          (get(props, "record.can_checkin") === false &&
            get(props, "record.is_checked_out"))
        }
        redirect={redirect}
        submitOnEnter={true}
      />
    )}
    {permissions && permissions !== null && permissions.can_read_logs && (
      <ViewLogsButton
        onShowLogsRequest={onShowLogsRequest}
        addLeftPadding={
          get(props, "record.can_checkin") ||
          !get(props, "record.is_checked_out")
        }
      />
    )}
  </Toolbar>
);
export default compose(withStyles(styles))(FormToolbar);
