import React from "react";

import Notification from "./Notification";
import CustomLayout from "./CustomLayout";
import CustomSidebar from "./CustomSidebar";
import MyAppBar from "./AppBar";

const MySidebar = (props) => <CustomSidebar {...props} size={260} />;
const MyLayout = (props) => (
  <CustomLayout
    style={{ minHeight: "calc(100vh - 40px)" }}
    {...props}
    sidebar={MySidebar}
    appBar={MyAppBar}
    notification={Notification}
  />
);

export default MyLayout;
