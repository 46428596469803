import React from "react";
import PropTypes from "prop-types";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";
import MuiButton from "@material-ui/core/Button";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import compose from "recompose/compose";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { translate } from "ra-core";
import { Responsive, Button } from "react-admin";

const styles = theme =>
  createStyles({
    floating: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      margin: 0,
      top: "auto",
      right: 20,
      bottom: 60,
      left: "auto",
      position: "fixed",
      zIndex: 1000
    },
    floatingLink: {
      color: "inherit"
    }
  });
const linkWithSort = ({ link, currentSort }) =>
  currentSort
    ? `${link}?sort=${currentSort.field}&order=${currentSort.order}`
    : link;
const CreateButton = ({
  basePath = "explorer",
  filterValues: { id },
  className,
  classes = {},
  translate,
  label = "Add Folder",
  resource = "containers",
  currentSort,
  ...rest
}) => (
  <Responsive
    small={
      <MuiButton
        component={Link}
        variant="text"
        color="primary"
        className={classnames(classes.floating, className)}
        to={linkWithSort({
          link: `${basePath}/create/${id || ""}/${resource}`,
          currentSort
        })}
        aria-label={label && translate(label)}
        {...rest}
      >
        {resource === "containers" ? (
          <CreateNewFolderIcon />
        ) : (
          <CloudUploadIcon />
        )}
      </MuiButton>
    }
    medium={
      <Button
        component={Link}
        to={linkWithSort({
          link: `${basePath}/create/${id || ""}/${resource}`,
          currentSort
        })}
        className={className}
        label={label}
        {...rest}
      >
        {resource === "containers" ? (
          <CreateNewFolderIcon />
        ) : (
          <CloudUploadIcon />
        )}
      </Button>
    }
  />
);

CreateButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  size: PropTypes.string,
  translate: PropTypes.func.isRequired,
  icon: PropTypes.element
};

const enhance = compose(
  translate,
  onlyUpdateForKeys([
    "basePath",
    "label",
    "translate",
    "filterValues",
    "currentSort"
  ]),
  withStyles(styles)
);

export default enhance(CreateButton);
