import { fetchUtils } from "react-admin";
import { API_URL } from "./config";
import getHeaders from "./authHeaders";
import makeid from "./utils/makeid";

const addUserFeature = (requestHandler) => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (resource === "user-password-change" && type === "CREATE") {
    const url = `${API_URL}/users/change-password`;
    return fetchJson(url, {
      method: "POST",
      body: JSON.stringify(params.data),
      headers: getHeaders(),
    }).then((response) => {
      return {
        data: { ...response.json.data, id: makeid(5) },
      };
    });
  }
  if (resource === "reset-password" && type === "CREATE") {
    const url = `${API_URL}/users/reset-password`;
    return fetchJson(url, {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    }).then((response) => {
      return {
        data: { ...response.json, id: makeid(5) },
      };
    });
  }
  if (resource === "user-profile" && type === "GET_ONE") {
    const url = `${API_URL}/users/profile`;
    return fetchJson(url, {
      method: "GET",
      body: JSON.stringify(params.data),
      headers: getHeaders(),
    }).then((response) => {
      return {
        data: { ...response.json.data.profile, id: params.id },
      };
    });
  }
  if (resource === "user-profile" && type === "UPDATE") {
    const url = `${API_URL}/users/update-profile`;
    return fetchJson(url, {
      method: "POST",
      body: JSON.stringify(params.data),
      headers: getHeaders(),
    }).then((response) => {
      return { data: { id: params.data.id } };
    });
  }

  return requestHandler(type, resource, params);
};

export default addUserFeature;
