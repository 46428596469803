import React from "react";
import { Typography } from "@material-ui/core";

const NavText = ({ record, source, className }) => {
  return (
    <Typography variant="body1" component="span" className={className}>
      {record[source]}
    </Typography>
  );
};
export default NavText;
