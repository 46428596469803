import React, { useState } from "react";
import PropTypes from "prop-types";
import shouldUpdate from "recompose/shouldUpdate";
import ContentCopy from "@material-ui/icons/ContentCopy";
import { Link } from "react-router-dom";
import { Button, Mutation, Confirm } from "react-admin";
import { get } from "lodash";
import makeid from "../utils/makeid";
import { CLONE } from "../addExplorerFeature";
const options = {
  undoable: false,
  onSuccess: {
    notification: {
      body: "resources.explorer.messages.clone_executed",
      level: "info",
    },
    refresh: true,
  },
};
const CloneButton = ({
  basePath = "",
  record = {},
  source = "id",
  currentSort,
  translate,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  if (record.type !== "container") {
    return null;
  }
  if (get(record, "id") === "prev") {
    return null;
  }
  if (!get(record, "perms.can_edit") || !get(record, "perms.can_read")) {
    return null;
  }
  const handleClose = () => setOpen(false);
  const handleOpen = (e) =>
    !e.preventDefault() && !e.stopPropagation() && !setOpen(true);
  return (
    <>
      <Button
        component={Link}
        to={"/#"}
        label={"ra.action.clone"}
        onClick={handleOpen}
        {...rest}
      >
        <ContentCopy />
      </Button>
      <Mutation
        type={CLONE}
        resource={"explorer"}
        payload={record}
        options={options}
      >
        {(handleClone) => (
          <Confirm
            key={makeid(8)}
            isOpen={open}
            title={`resources.explorer.titles.clone`}
            content={`resources.explorer.messages.clone`}
            onConfirm={() => !setOpen(false) && handleClone()}
            onClose={handleClose}
          />
        )}
      </Mutation>
    </>
  );
};
CloneButton.propTypes = {
  currentSort: PropTypes.any,
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  record: PropTypes.object,
};

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record &&
      nextProps.record &&
      props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null) ||
    (props.record.perms &&
      (nextProps.record.perms !== props.record.perms ||
        props.record.perms.can_edit !== nextProps.record.perms.can_edit)) ||
    props.currentSort !== nextProps.currentSort
);

export default enhance(CloneButton);
