import React, { Fragment } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  LongTextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  translate,
} from "react-admin";
import PermsInput from "./input/Perms";
import { FormDataConsumer } from "react-admin";
import Location from "./Location";
import makeid from "../utils/makeid";
import Form, {
  formatForms,
  parseForms,
  reloadForms,
  mapHashedFormsMap,
  ensureFormRulesAreRespected,
  FormSelectItem,
} from "./input/Form";
import FormToolbar from "./FormToolbar";
import compose from "recompose/compose";
import withForms from "../forms/withForms";
import withMaps from "../maps/withMaps";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { change } from "redux-form";
import { REDUX_FORM_NAME } from "ra-core";
import WithContainerProps from "./WithContainerProps";
import { FormHelperText } from "@material-ui/core";

const styles = createStyles((theme) => ({
  select: {
    marginRight: theme.spacing.unit * 2,
    minWidth: 200,
  },
}));

/**
 * Reconstruct state necessary to prepare the UI to the next operation.
 * Containers and assets share the same props.
 */
const parseRoute = (props) => {
  let route = document.location.toString().split("#")[1];
  route = route.indexOf("?") !== -1 ? route.split("?")[0] : route;
  let args = route.split("/");
  let type = args[args.length - 1];
  let container = args[args.length - 2];
  let allForms = mapHashedFormsMap(props.forms) || [];

  let requiredForms = allForms
    .filter(
      (f) =>
        (type === "containers" && f.use_for_containers) ||
        (type === "assets" && f.use_for_assets)
    )
    .filter((f) => f.is_required && f.group_by === null)
    .map((f) => ({ form_id: f.id }));

  let defaults = {
    type: type,
    container_id: parseInt(container, 10) || null,
    forms: requiredForms,
    props: [],
    perms: [],
    user_perms: [],
  };
  return {
    type,
    container,
    defaults,
  };
};

const redirect = ({ search }, id, basePath, record) =>
  !console.error(search) &&
  `/explorer?filter=${JSON.stringify({
    id: record.container_id,
  })}&reload=${makeid(5)}${
    search && search !== null && search.length > 0
      ? "&" + search.substring(1)
      : ""
  }`;
const ExplorerForm = (
  { translate, classes, forms, maps, permissions, dispatch, ...props },
  routeData
) => (
  <Create
    {...props}
    resource={routeData.type}
    title={`resources.explorer.actions.add_${routeData.type.substring(
      0,
      routeData.type.length - 1
    )}`}
  >
    <SimpleForm
      toolbar={<FormToolbar />}
      redirect={redirect.bind(this, props.location)}
      defaultValue={routeData.defaults}
    >
      <FormDataConsumer>
        {({ formData }) => (
          <Fragment>
            <Location id={routeData.container || null} />
            {routeData.type === "containers" && (
              <TextInput
                source="name"
                label="resources.explorer.fields.name"
                style={{ display: "block", clear: "both" }}
                options={{
                  fullWidth: true,
                }}
              />
            )}
            {routeData.type === "assets" && (
              <FileInput source="files" multiple={true}>
                <FileField source="src" title="Title" />
              </FileInput>
            )}
            <ReferenceArrayInput
              source="forms"
              reference="forms"
              resource="explorer"
              className={classes.select}
              filter={{
                usage: routeData.type,
              }}
              format={formatForms}
              parse={parseForms}
              onChange={(e, selection) => {
                let safeSelection = ensureFormRulesAreRespected(
                  forms,
                  selection,
                  routeData.type
                );
                dispatch(
                  change(
                    REDUX_FORM_NAME,
                    "props",
                    reloadForms({
                      value: safeSelection,
                      forms,
                      formData,
                    })
                  )
                );
              }}
            >
              <SelectArrayInput
                optionText={(choice) => (
                  <FormSelectItem
                    choice={choice}
                    forms={forms}
                    formData={formData}
                  />
                )}
              />
            </ReferenceArrayInput>
            <br />
            <WithContainerProps id={routeData.container}>
              {({ data }) => (
                <Fragment>
                  <ReferenceInput
                    source="map_id"
                    reference="maps"
                    resource="explorer"
                    defaultValue={data !== null ? data.map_id : null}
                    className={classes.select}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  <SelectInput
                    source="map_shape_id"
                    defaultValue={data !== null ? data.map_shape_id : null}
                    choices={
                      (formData &&
                        formData.map_id &&
                        maps[formData.map_id] &&
                        maps[formData.map_id].shapes) ||
                      []
                    }
                  />
                </Fragment>
              )}
            </WithContainerProps>
            {formData &&
              formData.forms &&
              formData.forms.map((form, idx) => (
                <Form source="props" form={form} key={idx} />
              ))}
            <LongTextInput
              source="notes"
              label="resources.explorer.fields.notes"
            />
            {routeData.type === "containers" && (
              <BooleanInput
                source="is_pinned"
                label="resources.explorer.fields.is_pinned"
              />
            )}
            {routeData.type === "containers" && (
              <>
                <FormHelperText>
                  {translate("resources.explorer.fields.is_pinned_help")}
                </FormHelperText>
              </>
            )}

            <BooleanInput
              source="is_priority"
              label="resources.explorer.fields.is_priority"
            />
            <>
              <FormHelperText>
                {translate("resources.explorer.fields.is_priority_help")}
              </FormHelperText>
            </>
            {permissions && permissions.can_edit_perms === true && (
              <PermsInput
                source="perms"
                resource={`${routeData.type}-perms`}
                label="resources.explorer.fields.perms.name"
                objectType={routeData.type}
                formData={formData}
                disableRemove={false}
              />
            )}
          </Fragment>
        )}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

const ExplorerCreate = (props) => ExplorerForm(props, parseRoute(props));
export default compose(
  withStyles(styles),
  withForms,
  withMaps,
  translate
)(ExplorerCreate);
