import React from "react";
import { Admin, Resource } from "react-admin";

import authProvider from "./authProvider";
import dataProvider from "./dataProvider";
import addUploadFeature from "./addUploadFeature";
import addAuthHeaderFeature from "./addAuthHeaderFeature";
import addDashboardFeature from "./addDashboardFeature";
import addExplorerFeature from "./addExplorerFeature";
import addRecyclerFeature from "./addRecyclerFeature";
import addQueriesFeature from "./addQueriesFeature";
import addLoginImageFeature from "./addLoginImageFeature";
import addUserFeature from "./addUserFeature";
import addArchiveFeature from "./addArchiveFeature";

import Menu from "./Menu";
import Layout from "./components/Layout";

import errorSagas from "./sagas/errors";
import theme from "./theme";

import users from "./users";
import groups from "./groups";
import customRoutes from "./customRoutes";
import explorer from "./explorer";
import forms from "./forms";
import loginImages from "./login-images";

import italianMessages from "./i18n/it";
import englishMessages from "ra-language-english";
import maps from "./maps";

import { Dashboard } from "./dashboard";
import recycler from "./explorer/recycler";
import queries from "./queries";
import { API_URL } from "./config";
import getHeaders from "./authHeaders";
import createResource from "./queries/createResource";

import CustomLoginPage from "./customLoginPage";
import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import archives from "./archives";
import archiveLogs from "./archive-logs";

import userProfile from "./user-profile";
import userPasswordChange from "./user-password-change";

const messages = {
  it: italianMessages,
  en: englishMessages,
};
const i18nProvider = (locale) => messages[locale];

let dp = addUploadFeature(dataProvider);
dp = addAuthHeaderFeature(dp);
dp = addExplorerFeature(dp);
dp = addRecyclerFeature(dp);
dp = addDashboardFeature(dp);
dp = addQueriesFeature(dp);
dp = addLoginImageFeature(dp);
dp = addArchiveFeature(dp);
dp = addUserFeature(dp);

const basicResources = [
  <Resource name="explorer" {...explorer} />,
  <Resource name="assets" />,
  <Resource name="containers" />,
  <Resource name="archives" {...archives} />,
  <Resource name="archive-logs" {...archiveLogs} />,
  <Resource name="maps" {...maps} />,

  <Resource name="users" {...users} />,
  <Resource name="roles" />,
  <Resource name="groups" {...groups} />,
  <Resource name="forms" {...forms} />,

  <Resource name="user-profile" {...userProfile} />,
  <Resource name="user-password-change" {...userPasswordChange} />,

  <Resource name="recycler" {...recycler} />,
  <Resource name="queries" {...queries} />,
  <Resource name="login-images" {...loginImages} />,
];

// Thanks to: https://marmelab.com/react-admin/doc/2.9/Admin.html
const fetchQueries = (permissions) =>
  fetch(`${API_URL}/queries/load`, {
    method: "GET",
    headers: getHeaders(),
  })
    .then((response) => response.json())
    .then(({ data }) =>
      (
        (data &&
          data.queries &&
          data.queries.map((query) => createResource(query))) ||
        []
      ).concat(basicResources)
    );

const App = () => (
  <DndProvider backend={Backend}>
    <Admin
      customSagas={[errorSagas]}
      customRoutes={customRoutes}
      menu={Menu}
      theme={theme}
      locale="it"
      appLayout={Layout}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      dataProvider={dp}
      dashboard={Dashboard}
      loginPage={CustomLoginPage}
    >
      {fetchQueries}
    </Admin>
  </DndProvider>
);
export default App;
