import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { translate } from "react-admin";
import compose from "recompose/compose";

const parseRoute = fn => {
  let route = document.location.toString().split("#")[1];
  if (route.indexOf("?") !== -1) {
    let queryString = new URLSearchParams(route.split("?")[1]);
    let filter = JSON.parse(queryString.get("filter") || "{}");
    return fn({
      q: filter.q || null,
      id: filter.id || null,
      route: route.indexOf("/explorer") !== -1 ? "explorer" : "recycler"
    });
  }
  return fn({
    q: null,
    id: null,
    route: route.indexOf("/explorer") !== -1 ? "explorer" : "recycler"
  });
};

const EmptyMessageBox = withStyles(theme => ({
  caption: {
    padding: theme.spacing.unit * 2,
    alignSelft: "center"
  }
}))(({ translate, total, classes }) =>
  parseRoute(
    filters =>
      ((filters.route !== "recycler" && filters.q === null && total === 1) ||
        (filters.route !== "recycler" && filters.q !== null && total === 0) ||
        (filters.route === "recycler" && total === 0)) && (
        <Typography className={classes.caption} variant="subheading">
          {translate("resources.explorer.messages.no_data")}
        </Typography>
      )
  )
);

export default compose(translate)(EmptyMessageBox);
