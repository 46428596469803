import Icon from "@material-ui/icons/DeleteSweep";
import List from "./List";

export default {
  options: {
    group: "dashboard"
  },
  icon: Icon,
  list: List
};
