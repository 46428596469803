import React from "react";
import PropTypes from "prop-types";
import shouldUpdate from "recompose/shouldUpdate";
import RestoreIcon from "@material-ui/icons/Restore";
import { Mutation, translate } from "ra-core";
import { Button } from "@material-ui/core";
import compose from "recompose/compose";
import { RESTORE } from "../addRecyclerFeature";

const options = {
  undoable: false,
  onSuccess: {
    notification: { body: "Item restored.", level: "info" },
    refresh: true
  }
};

const RestoreButton = ({
  basePath = "",
  label = "resources.explorer.actions.restore",
  record = {},
  source = "id",
  icon = <RestoreIcon />,
  translate,
  ...rest
}) =>
  ["container", "asset"].indexOf(record.type) !== -1 &&
  record.id !== "prev" &&
  record.perms &&
  record.perms.can_edit === true && (
    <Mutation
      type={RESTORE}
      resource={"recycler"}
      payload={record}
      options={options}
    >
      {restore => (
        <Button startIcon={icon} onClick={restore} {...rest}>
          {translate(label)}
        </Button>
      )}
    </Mutation>
  );

RestoreButton.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element
};

const enhance = shouldUpdate(
  (props, nextProps) =>
    props.translate !== nextProps.translate ||
    (props.record &&
      nextProps.record &&
      props.record.id !== nextProps.record.id) ||
    props.basePath !== nextProps.basePath ||
    (props.record == null && nextProps.record != null) ||
    (props.record.perms &&
      (nextProps.record.perms !== props.record.perms ||
        props.record.perms.can_edit !== nextProps.record.perms.can_edit))
);

export default compose(translate)(enhance(RestoreButton));
