import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import red from "@material-ui/core/colors/red";
import { amber } from "@material-ui/core/colors";

export default createMuiTheme({
  palette: {
    primary: indigo,
    secondary: amber,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.3
  },
  overrides: {}
});
