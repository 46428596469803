import React from "react";
import { API_URL } from "../config";
import { Typography } from "@material-ui/core";
import Link from "../components/Link";
import { translate, refreshView, showNotification } from "react-admin";
import { compose } from "recompose";

import { connect } from "react-redux";
import { get } from "lodash";
import DragAndDrop from "../components/DragAndDrop";

const parseRoute = (fn) => {
  let route = document.location.toString().split("#")[1];
  let queryString = route.split("?")[1];
  let qs = new URLSearchParams(queryString);
  let filter = JSON.parse(qs.get("filter") || "{}");
  let data = {
    id: filter.id || null,
  };
  return fn(data);
};

const NavLink = ({
  record,
  source,
  children,
  dispatch,
  sort,
  basePath,
  search = false,
  translate,
}) => {
  return parseRoute((queryString) =>
    (record.type === "asset" && !record.perms.can_download) ||
    basePath === "/recycler" ? (
      <Typography variant="body1">
        {React.Children.map(children, (child) =>
          React.cloneElement(child, {
            record,
            source,
            dispatch,
          })
        )}
      </Typography>
    ) : (
      <DragAndDrop node={record}>
        <Link
          variant="subheading"
          href={
            record.type !== "asset"
              ? record.record_id > 0
                ? `#/explorer?filter=${JSON.stringify({
                    id: record.record_id,
                    q: queryString.q,
                    path: record.path,
                  })}&sort=${sort.field}&order=${sort.order}`
                : `#/explorer?filter=${JSON.stringify({
                    id: null,
                    q: queryString.q,
                    path: record.path,
                  })}&sort=${sort.field}&order=${sort.order}`
              : `${API_URL}/explorer/${record.record_id}/${
                  record.type
                }/download?token=${localStorage.getItem("token")}`
          }
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              record,
              source,
            })
          )}
          <Typography variant="caption">
            {search && get(record, "tree", "/").substr(1)}
          </Typography>
          {source === "name" &&
            get(record, "type") === "asset" &&
            get(record, "checked_out") && (
              <Typography variant="body2" color="textPrimary">
                {translate(
                  get(record, "perms.can_checkin")
                    ? "resources.explorer.messages.checked_out_short"
                    : "resources.explorer.messages.checked_out_from_short",
                  get(record, "owner")
                )}
              </Typography>
            )}
          {source === "name" && get(record, "priority") && (
            <Typography variant="body2" color="textSecondary">
              {translate("resources.explorer.messages.priority")}
            </Typography>
          )}
        </Link>
      </DragAndDrop>
    )
  );
};
export default compose(
  translate,
  connect(null, {
    refreshView,
    showNotification,
  })
)(NavLink);
