import React from "react";
import { ListController } from "ra-core";
import { ListView } from "./ListView";

export const DumbList = props => (
  <ListController {...props}>
    {controllerProps => (
      <ListView
        {...props}
        // This is important, otherwise bulkActionsToolbar ends up on very top of the page
        classes={{ card: "relative" }}
        {...controllerProps}
      />
    )}
  </ListController>
);
