import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  CheckboxGroupInput,
  ReferenceArrayInput
} from "react-admin";
import PermsInput from "./inputs/Perms";

const FormatRoles = roles => (roles ? roles.map(r => r.id) : []);
const ParseRoles = roles => (roles ? roles.map(id => ({ id })) : []);

const FormatGroups = groups => (groups ? groups.map(g => g.id) : []);
const ParseGroups = groups => (groups ? groups.map(id => ({ id })) : []);

const UserEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <BooleanInput source="is_active" />
      <TextInput source="email" />
      <TextInput source="username" />
      <TextInput source="password" type="password" />
      <TextInput source="profile.name" />
      <TextInput source="profile.surname" />
      <ReferenceArrayInput
        format={FormatRoles}
        parse={ParseRoles}
        source="roles"
        reference="roles"
      >
        <CheckboxGroupInput />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        format={FormatGroups}
        parse={ParseGroups}
        source="groups"
        reference="groups"
      >
        <CheckboxGroupInput />
      </ReferenceArrayInput>
      <PermsInput source="perms" />
    </SimpleForm>
  </Edit>
);
export default UserEdit;
