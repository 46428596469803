import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import {
  Datagrid,
  DatagridBody,
  DateField,
  ArrayField,
  ChipField,
  SingleFieldList,
} from "react-admin";
import { DumbList } from "./DumbList";
import { withDataProvider, GET_LIST } from "ra-core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { get } from "lodash";
import compose from "recompose/compose";

import Actions from "./Actions";
import EmptyMessageBox from "./EmptyMessageBox";
import Breadcrumbs from "./Breadcrumbs";
import NavLink from "./NavLink";
import NavText from "./NavText";
import NavIcon from "./NavIcon";
import BulkActions from "../components/list/BulkActions";
import ListRow from "./ListRow";
import Filters from "./Filters";
import NavSize from "./NavSize";
import Drawer from "@material-ui/core/Drawer";
import NavEye from "./NavEye";
import View from "./View";
import EditButton from "./EditButton";
import RestoreButton from "./RestoreButton";
import CloneButton from "./CloneButton";

import makeid from "../utils/makeid";
import ArchiveWarn from "./ArchiveWarn";

const breadcrumbs = (props) => {
  const { location } = props.filterValues.id
    ? props.data["prev"] || { location: [] }
    : { location: [] };
  return location;
};
const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    list: {
      flexGrow: 1,
      transition: theme.transitions.create(["all"], {
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    listWithDrawer: {
      marginRight: 400,
    },
    drawerPaper: {
      margin: 0,
      overflow: "hidden",
    },
  });
const MyDatagrid = ({ onViewClick, basePath, ...props }) => (
  <React.Fragment>
    <ArchiveWarn id={props.filterValues.id} />
    <Datagrid {...props} body={<DatagridBody row={<ListRow />} />}>
      <NavLink
        source="type"
        sort={props.currentSort}
        sortable={false}
        basePath={basePath}
      >
        <NavIcon />
      </NavLink>
      <NavLink
        draggable={true}
        source="name"
        sort={props.currentSort}
        basePath={basePath}
        search={
          !!get(props, "filterValues.q") ||
          !!get(props, "filterValues.view_kind")
        }
      >
        <NavText />
      </NavLink>
      <NavLink source="size" sort={props.currentSort} basePath={basePath}>
        <NavSize />
      </NavLink>
      <ArrayField source="groups" sortable={false}>
        <SingleFieldList>
          <ChipField source="code" />
        </SingleFieldList>
      </ArrayField>
      <NavLink
        source={basePath === "/recycler" ? "deleted" : "modified"}
        sort={props.currentSort}
        basePath={basePath}
      >
        <DateField showTime />
      </NavLink>
      <NavEye onClick={onViewClick} />
      {basePath === "/explorer" && (
        <EditButton basePath={basePath} currentSort={props.currentSort} />
      )}
      {basePath === "/explorer" && <CloneButton basePath={basePath} />}
      {basePath === "/recycler" && <RestoreButton />}
    </Datagrid>
    <EmptyMessageBox {...props} />
    <Breadcrumbs loading={props.isLoading} breadcrumbs={breadcrumbs(props)} />
  </React.Fragment>
);

class ExploreList extends Component {
  state = {
    selected: null,
    forms: [],
    key: makeid(8),
  };

  constructor(props) {
    super(props);

    this.handleOpenView = this.handleOpenView.bind(this);
    this.handleCloseView = this.handleCloseView.bind(this);
  }

  componentDidMount() {
    const { dataProvider } = this.props;
    dataProvider(GET_LIST, "forms", {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "DESC" },
    }).then(({ data }) => {
      this.setState({ forms: data, key: makeid(8) });
    });
  }

  handleCloseView() {
    this.setState({ selected: null });
  }
  handleOpenView(record) {
    this.setState({ selected: record });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== !this.props.location.search) {
      this.setState({ selected: null });
    }
  }

  render() {
    const {
      classes,
      permissions,
      dataProvider,
      dispatch,
      ...props
    } = this.props;
    return (
      <Fragment>
        <DumbList
          {...props}
          key={this.state.key}
          pagination={null}
          sort={{ field: "name", order: "asc" }}
          filters={<Filters key={this.state.key} forms={this.state.forms} />}
          title="resources.explorer.name"
          actions={<Actions permissions={permissions || {}} />}
          bulkActionButtons={<BulkActions />}
        >
          <MyDatagrid onViewClick={this.handleOpenView} />
        </DumbList>
        <Drawer
          variant="persistent"
          open={this.state.selected !== null}
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
          {this.state.selected && (
            <View
              onCancel={this.handleCloseView}
              record={this.state.selected}
            />
          )}
        </Drawer>
      </Fragment>
    );
  }
}
ExploreList.propTypes = {
  classes: PropTypes.object.isRequired,
  permissions: PropTypes.any,
};

export default compose(withStyles(styles), withDataProvider)(ExploreList);
