import React, { Component } from "react";
import { Responsive, withDataProvider } from "react-admin";
import compose from "recompose/compose";
import { connect } from "react-redux";

import StorageIcon from "@material-ui/icons/Storage";
import FolderIcon from "@material-ui/icons/Folder";
import FileDownloadIcon from "@material-ui/icons/FileDownload";
import Indicator from "./Indicator";
import { GET_STATS } from "../addDashboardFeature";
import filesize from "../utils/filesize";

const styles = {
  flex: { display: "flex", marginLeft: "1em" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" }
};

class Dashboard extends Component {
  state = {
    stats: {
      used_space: 0,
      containers: 0,
      assets: 0
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // handle refresh
    if (this.props.version !== prevProps.version) {
      this.fetchData();
    }
  }

  async fetchData() {
    const { dataProvider } = this.props;
    const response = await dataProvider(GET_STATS, "dashboard", {});
    this.setState({
      stats: response.data
    });
  }

  render() {
    const { stats } = this.state;
    return (
      <Responsive
        xsmall={
          <div>
            <div style={styles.flexColumn}>
              <div style={styles.flex}>
                <Indicator
                  label="dashboard.used_space"
                  value={filesize(stats.used_space)}
                  icon={StorageIcon}
                />
                <Indicator
                  label="dashboard.containers"
                  value={stats.containers}
                  icon={FolderIcon}
                />
              </div>
            </div>
          </div>
        }
        small={
          <div style={styles.flexColumn}>
            <div style={styles.flex}>
              <Indicator
                label="dashboard.used_space"
                value={filesize(stats.used_space)}
                icon={StorageIcon}
              />
              <Indicator
                label="dashboard.containers"
                value={stats.containers}
                icon={FolderIcon}
              />
            </div>
          </div>
        }
        medium={
          <div style={styles.flex}>
            <div style={styles.leftCol}>
              <div style={styles.flex}>
                <Indicator
                  color="#f44336"
                  label="dashboard.used_space"
                  value={filesize(stats.used_space)}
                  icon={StorageIcon}
                />
                <Indicator
                  color="#ffd54f"
                  label="dashboard.containers"
                  value={stats.containers}
                  icon={FolderIcon}
                />
                <Indicator
                  color="#607d8b"
                  label="dashboard.assets"
                  value={stats.assets}
                  icon={FileDownloadIcon}
                />
              </div>
            </div>
          </div>
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  version: state.admin.ui.viewVersion
});

export default compose(connect(mapStateToProps), withDataProvider)(Dashboard);
