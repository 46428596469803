import React, { Component, Fragment, isValidElement } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { linkToRecord } from "ra-core";

import { DatagridCell } from "react-admin";
import { yellow } from "@material-ui/core/colors";

const sanitizeRestProps = ({
  basePath,
  children,
  classes,
  className,
  rowClick,
  id,
  isLoading,
  onToggleItem,
  push,
  record,
  resource,
  selected,
  style,
  styles,
  ...rest
}) => rest;

class ListRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      colSpan: this.computeColSpan(props),
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const colSpan = this.computeColSpan(this.props);
    if (colSpan !== prevState.colSpan) {
      this.setState({ colSpan });
    }
  };

  handleToggleExpanded = (event) => {
    this.setState((state) => ({ expanded: !state.expanded }));
    event.stopPropagation();
  };

  handleToggle = (event) => {
    this.props.onToggleItem(this.props.id);
    event.stopPropagation();
  };

  handleClick = async (event) => {
    const { basePath, rowClick, id, record } = this.props;

    if (!rowClick) return;

    if (typeof rowClick === "function") {
      const path = await rowClick(id, basePath, record);
      this.handleRedirection(path, event);
      return;
    }

    this.handleRedirection(rowClick, event);
  };

  handleRedirection = (path, event) => {
    const { basePath, id, push } = this.props;

    if (path === "edit") {
      push(linkToRecord(basePath, id));
      return;
    }
    if (path === "show") {
      push(linkToRecord(basePath, id, "show"));
      return;
    }
    if (path === "expand") {
      this.handleToggleExpanded(event);
      return;
    }
    if (!path) return;

    push(path);
  };

  computeColSpan = (props) => {
    const { children, hasBulkActions } = props;
    return (
      1 + // show expand button
      (hasBulkActions ? 1 : 0) + // checkbox column
      React.Children.toArray(children).filter((child) => !!child).length // non-null children
    );
  };

  render() {
    const {
      basePath,
      children,
      classes,
      className,
      expand,
      hasBulkActions,
      hover,
      id,
      record,
      resource,
      selected,
      style,
      styles,
      ...rest
    } = this.props;
    return (
      <Fragment>
        <TableRow
          className={className}
          key={id}
          style={{
            ...style,

            ...(record && record.priority
              ? {
                  borderLeftWidth: 4,
                  borderLeftColor: yellow[500],
                  borderLeftStyle: "solid",
                }
              : {}),
          }}
          hover={hover}
          onClick={this.handleClick}
          {...sanitizeRestProps(rest)}
        >
          {hasBulkActions && (
            <TableCell padding={id !== "prev" ? "none" : "checkbox"}>
              {id !== "prev" && (
                <Checkbox
                  color="primary"
                  className={`select-item ${classes.checkbox}`}
                  checked={selected}
                  style={{ marginLeft: record && record.priority ? -4 : -2 }}
                  onClick={this.handleToggle}
                />
              )}
              {id === "prev" && " "}
            </TableCell>
          )}
          {React.Children.map(children, (field, index) =>
            isValidElement(field) ? (
              <DatagridCell
                key={`${id}-${field.props.source || index}`}
                className={classNames(
                  `column-${field.props.source}`,
                  classes.rowCell
                )}
                record={record}
                {...{ field, basePath, resource }}
              />
            ) : null
          )}
        </TableRow>
      </Fragment>
    );
  }
}

ListRow.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  expand: PropTypes.node,
  hasBulkActions: PropTypes.bool.isRequired,
  hover: PropTypes.bool,
  id: PropTypes.any,
  onToggleItem: PropTypes.func,
  push: PropTypes.func,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string,
  rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  selected: PropTypes.bool,
  style: PropTypes.object,
  styles: PropTypes.object,
};

ListRow.defaultProps = {
  hasBulkActions: false,
  hover: true,
  record: {},
  selected: false,
};

// wat? TypeScript looses the displayName if we don't set it explicitly
ListRow.displayName = "ListRow";

export default connect(null, { push })(ListRow);
