import React from "react";
import { Query, Loading } from "react-admin";
import { APP_URL } from "./config";
import {
  Login,
  LoginForm,
  withDataProvider,
  showNotification,
  translate,
} from "react-admin";

import { withStyles, createStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { compose } from "recompose";
import { connect } from "react-redux";
import { push } from "react-router-redux";

const styles = (theme) =>
  createStyles({
    background: {
      backgroundColor: "GhostWhite",
    },
    version: {
      position: "fixed",
      bottom: theme.spacing.unit,
      right: theme.spacing.unit,
    },
    reset: {
      marginLeft: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
  });

const CustomLoginPage = ({ classes, translate, ...props }) => (
  <Query type="GET_RANDOM_IMAGE" resource="login-images">
    {({ data, loading, error }) => {
      if (loading) {
        return <Loading />;
      }
      if (error) {
        return (
          <Login
            backgroundImage=""
            className={classes.background}
            loginForm={
              <div>
                <LoginForm />
                <Button
                  color="primary"
                  size="small"
                  className={classes.reset}
                  href="#/reset-password"
                >
                  {translate("ra.auth.forgot_password")} &rarr;
                </Button>
              </div>
            }
          />
        );
      }
      return (
        <Login
          backgroundImage={
            data !== null && data.path !== null ? APP_URL + data.path : ""
          }
          className={classes.background}
          loginForm={
            <div>
              <LoginForm />
              <Button
                color="primary"
                size="small"
                className={classes.reset}
                href="#/reset-password"
              >
                {translate("ra.auth.forgot_password")} &rarr;
              </Button>
            </div>
          }
        />
      );
    }}
  </Query>
);

export default compose(
  translate,
  withStyles(styles),
  withDataProvider,
  connect(null, { showNotification, push })
)(CustomLoginPage);
