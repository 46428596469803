import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  CheckboxGroupInput
} from "react-admin";
import PermsInput from "./inputs/Perms";

const FormatRoles = roles => (roles ? roles.map(r => r.id) : []);
const ParseRoles = roles => (roles ? roles.map(id => ({ id })) : []);

const FormatGroups = groups => (groups ? groups.map(g => g.id) : []);
const ParseGroups = groups => (groups ? groups.map(id => ({ id })) : []);

const UserCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="email" />
      <TextInput source="username" />
      <TextInput source="password" type="password" />
      <TextInput source="profile.name" defaultValue="" />
      <TextInput source="profile.surname" defaultValue="" />
      <ReferenceArrayInput
        format={FormatRoles}
        parse={ParseRoles}
        source="roles"
        reference="roles"
      >
        <CheckboxGroupInput />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        format={FormatGroups}
        parse={ParseGroups}
        source="groups"
        reference="groups"
      >
        <CheckboxGroupInput />
      </ReferenceArrayInput>
      <PermsInput source="perms" />
    </SimpleForm>
  </Create>
);
export default UserCreate;
