import React, { Component } from "react";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import { withStyles } from "@material-ui/core/styles";
import { translate, withDataProvider } from "react-admin";
import { LinearProgress, Typography, Slide, Divider } from "@material-ui/core";
import compose from "recompose/compose";
import Link from "./Link";
import TreeItem from "./TreeItem";
import TreeView from "./TreeView";
import { GET_ROOTS, GET_CHILDREN } from "../addExplorerFeature";
import DragAndDrop from "./DragAndDrop";
import { clearAction, PARAMS, isMoveContainer } from "./FileSystemActions";
import filesize from "../utils/filesize";
const styles = (theme) => ({
  title: {
    marginLeft: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1,
    color: theme.palette.text.secondary,
  },
  root: {
    flexGrow: 1,
    maxWidth: 400,
    marginLeft: theme.spacing.unit * 1.1,
    marginRight: theme.spacing.unit * 1,
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing.unit * 1,
    marginLeft: theme.spacing.unit * 0,
    marginRight: theme.spacing.unit * 0,
  },
  loader: {
    margin: theme.spacing.unit * 0,
    padding: theme.spacing.unit * 0,
  },
  loaderIcon: {
    marginRight: theme.spacing.unit * 1,
  },

  containerNode: {},
  content: {
    borderRadius: theme.spacing.unit * 2,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    paddingTop: theme.spacing.unit * 0.5,
    paddingBottom: theme.spacing.unit * 0.5,
    marginBottom: theme.spacing.unit * 0.5,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  expanded: {
    "$expanded > content": {
      fontWeight: "bold",
    },
  },

  overDrop: {
    fontWeight: "bold",
  },
  cantDrop: {
    cursor: "not-allowed",
  },
});

const LoaderNode = compose(withStyles(styles))(({ classes }) => (
  <TreeItem
    nodeId="loader"
    label={
      <span className={classes.loader}>
        <LinearProgress size={16} className={classes.loaderIcon} />
      </span>
    }
  />
));

const withChildren = ({ node, loaded, expanded, onNodeOpened }) => {
  const children = [
    node.has_children && !loaded[node.id] && <LoaderNode key="loader" />,
    (node.has_children || loaded[node.id]) &&
      loaded[node.id] &&
      loaded[node.id].map((child) => (
        <ContainerNode
          {...{
            key: child.id,
            node: child,
            loaded,
            expanded,
            onNodeOpened,
          }}
        />
      )),
  ].filter((c) => c && c !== null);
  return (fn) => fn(children);
};

const ContainerNode = compose(
  withStyles(styles),
  withDataProvider
)(({ node, loaded, expanded, dataProvider, classes, onNodeOpened }) => {
  return withChildren({ node, loaded, expanded, onNodeOpened })((children) =>
    children.length > 0 ? (
      <TreeItem
        nodeId={node.id}
        className={classes.containerNode}
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        label={
          <DragAndDrop node={node}>
            <Link
              onClick={(e) => e.stopPropagation()}
              href={`#/explorer?filter=${JSON.stringify({
                id: node.record_id,
                path: node.path,
              })}`}
              style={{ marginLeft: 8 }}
              variant="subheading"
            >
              {node.name}
              <Typography variant="caption">{filesize(node.size)}</Typography>
            </Link>
          </DragAndDrop>
        }
        onClick={() =>
          dataProvider(GET_CHILDREN, "explorer", node).then((response) =>
            onNodeOpened(node.id, response.data.children)
          )
        }
      >
        {children}
      </TreeItem>
    ) : (
      <TreeItem
        nodeId={node.id}
        classes={{
          content: classes.content,
        }}
        label={
          <DragAndDrop node={node}>
            <Link
              href={`#/explorer?filter=${JSON.stringify({
                id: node.record_id,
                path: node.path,
              })}`}
              style={{ marginLeft: 8 }}
              variant="subheading"
            >
              {node.name}
              <Typography variant="caption">{filesize(node.size)}</Typography>
            </Link>
          </DragAndDrop>
        }
      ></TreeItem>
    )
  );
});
const FileSystemNavigatorContent = compose(
  translate,
  withStyles(styles)
)(
  ({
    classes,
    open,
    translate,
    expanded,
    loaded,
    onNodeOpened,
    roots,
    permissions,
  }) => {
    return (
      <Slide direction="right" in={open} mountOnEnter unmountOnExit>
        <div>
          <Typography variant="headline" className={classes.title}>
            {translate("resources.explorer.navigator")}
          </Typography>
          <TreeView
            className={classes.root}
            defaultExpanded={expanded}
            defaultEndIcon={<ExpandMoreIcon />}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {roots.map((root) => (
              <ContainerNode
                {...{
                  key: root.id,
                  node: root,
                  expanded,
                  loaded,
                  onNodeOpened,
                }}
              />
            ))}
          </TreeView>
          <Divider className={classes.divider} />
        </div>
      </Slide>
    );
  }
);

class FileSystemNavigator extends Component {
  constructor(props) {
    super(props);

    const path = this.getCurrentPath();
    this.state = {
      currentPath: path.name, // Currently opened path.
      expanded: path.segments, // Segments (Nodes) that should be expanded because currently opened.
      loaded: {}, // Not yet used.
      reload: 0, // Indicates if navigator need reloading.
      roots: [], // Loaded roots.

      action: null,
    };
  }

  componentDidMount() {
    const { dataProvider } = this.props;
    dataProvider(GET_ROOTS, "explorer", {}).then((response) => {
      this.setState(
        {
          roots: response.data.roots,
        },
        () => this.expandTree()
      );
    });

    this._routeCheckerRef = setInterval(() => {
      const routeParams = this.parseRoute();
      if (
        this.state.action !== routeParams.action &&
        routeParams.action &&
        routeParams.action !== null &&
        isMoveContainer(routeParams.action)
      ) {
        this.setState(
          {
            action: routeParams.action,
          },
          () => {
            let loaded = this.state.loaded;
            let sourceContainerId = `containers/${routeParams.sourceContainerId}`;
            if (loaded[sourceContainerId]) {
              console.info("filtering:", {
                sourceContainerId,
                routeParamsSourceId: routeParams.sourceId,
                loaded: loaded[sourceContainerId],
              });
              loaded[sourceContainerId] = loaded[sourceContainerId].filter(
                (l) => l.record_id !== parseInt(routeParams.sourceId, 10)
              );
            }

            dataProvider(GET_CHILDREN, "explorer", {
              record_id: routeParams.destinationId,
            }).then((response) =>
              this.setState({
                loaded: {
                  ...loaded,
                  [`containers/${routeParams.destinationId}`]: response.data
                    .children,
                },
              })
            );

            this.setState({ loaded }, () => clearAction());
          }
        );
      }
      if (this.state.reload !== routeParams.reload) {
        this.setState({ reload: routeParams.reload }, () => {
          const id = routeParams.id;
          if (id && id !== null) {
            if (this.state.roots.find((root) => root.record_id === id)) {
              dataProvider(GET_ROOTS, "explorer", {}).then((response) =>
                this.setState({
                  roots: response.data.roots,
                })
              );
            }
            dataProvider(GET_CHILDREN, "explorer", { record_id: id }).then(
              (response) =>
                this.setState({
                  loaded: {
                    ...this.state.loaded,
                    [`containers/${id}`]: response.data.children,
                  },
                })
            );
          } else {
            dataProvider(GET_ROOTS, "explorer", {}).then((response) =>
              this.setState({
                roots: response.data.roots,
              })
            );
          }
        });
      } else {
        const currentPath = this.getCurrentPath();
        if (this.state.currentPath !== currentPath.name) {
          this.setState(
            {
              currentPath: currentPath.name,
            },
            () => this.expandTree()
          );
        }
      }
    }, 250);
  }

  componentWillUnmount() {
    clearInterval(this._routeCheckerRef);
  }

  render() {
    const { open, permissions } = this.props;
    const { expanded, loaded, roots } = this.state;

    return (
      <FileSystemNavigatorContent
        open={open}
        expanded={expanded}
        loaded={loaded}
        roots={roots}
        permissions={permissions}
        onNodeOpened={(id, children) =>
          this.setState({
            ...this.state,
            expanded: expanded.concat([id]),
            loaded: {
              ...this.state.loaded,
              [id]: children,
            },
          })
        }
      />
    );
  }

  expandTree() {
    const { dataProvider } = this.props;
    const { state } = this;
    const { loaded } = state;
    let path = this.getCurrentPath();
    if (path.name !== null) {
      let expanded = path.segments;
      let loadable = expanded.filter((e) => !loaded[e]);
      Promise.all(
        loadable.map((id) =>
          dataProvider(GET_CHILDREN, "explorer", {
            record_id: id.split("/")[1],
          }).then((response) => ({
            [id]: response.data.children,
          }))
        )
      ).then((responses) =>
        this.setState({
          expanded: Object.keys(loaded).concat(expanded),
          loaded: {
            ...loaded,
            ...responses.reduce(
              (theObject, item) => ({
                ...theObject,
                ...item,
              }),
              {}
            ),
          },
        })
      );
    }
  }

  getCurrentPath() {
    const route = this.parseRoute();
    const name = route.path || "";
    const segments = name
      .split("/")
      .filter((e) => e && e !== null)
      .map((e) => `containers/${e}`);
    return {
      name,
      segments,
    };
  }

  parseRoute() {
    let route = document.location.toString().split("#")[1];
    if (route.indexOf("?") !== -1) {
      let queryString = new URLSearchParams(route.split("?")[1]);
      let filter = JSON.parse(queryString.get("filter") || "{}");
      return {
        q: filter.q || null,
        id: filter.id || null,
        path: filter.path || "",
        reload: queryString.get("reload"),
        action: queryString.get(PARAMS.ACTION) || null,
        sourceId: queryString.get(PARAMS.SOURCE_ID) || null,
        sourceContainerId: queryString.get(PARAMS.SOURCE_CONTAINER_ID) || null,
        destinationId: queryString.get(PARAMS.DESTINATION_ID) || null,
        destinationContainerId: queryString.get(
          PARAMS.DESTINATION_CONTAINER_ID
        ),
      };
    }
    return {
      q: null,
      id: null,
      path: null,
      reload: 0,
    };
  }
}

FileSystemNavigatorContent.propTypes = {
  open: PropTypes.bool.isRequired,
  permissions: PropTypes.func,
};
export default compose(withDataProvider)(FileSystemNavigator);
