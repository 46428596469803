import React from "react";
import { Route } from "react-router-dom";
import { MapViewerIcon } from "./icons";
import MapViewer from "./map-viewer";
import PasswordResetPage from "./passwordResetPage";

export default [
  <Route
    path="/map-viewer"
    component={MapViewer}
    options={{
      icon: MapViewerIcon,
      group: "dashboard",
      label: "menu.items.map",
    }}
  />,
  <Route
    exact
    path="/reset-password"
    render={() => <PasswordResetPage />} // Se usiamo component insieme alla prop noLayout, react da errore nella console
    noLayout
    options={{
      menu: false,
    }}
  />,
];
