import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Filter, TextInput, ReferenceInput, SelectInput } from "react-admin";
import Field from "../forms/input";

import { change } from "redux-form";
import useDebounce from "./useDebounce";

const VIEW_KIND = ["checked_out"].map((id) => ({
  id,
  name: `resources.explorer.view_kind.${id}`,
}));

const filters = (forms) =>
  forms.reduce(
    (filters, form) =>
      filters.concat(
        form.props.map((prop) => ({
          ...prop,
          name: `p_${prop.id}`,
          label: `${form.name}/${prop.name}`,
        }))
      ),
    []
  );

const Filterable = connect()(
  ({ prop, dispatch, resource, source, translate, meta, ...state }) => {
    const [value, setValue] = useState("");
    const debouncedValue = useDebounce(value, 500);
    useEffect(() => {
      dispatch(change(meta.form, prop.name, debouncedValue));
    }, [debouncedValue, dispatch, meta.form, prop.name]);
    return (
      <Field
        source={prop.name}
        label={prop.label}
        resettable={true}
        prop={{ ...prop, value }}
        onChange={(v) => {
          setValue(v);
        }}
      />
    );
  }
);

const ExplorerFilters = ({ forms, dispatch, ...props }) => (
  <Filter {...props}>
    <TextInput style={{ minWidth: 250 }} source="q" alwaysOn />
    <SelectInput source="view_kind" choices={VIEW_KIND} />
    <ReferenceInput source="form" reference="forms" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="group" reference="groups" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
    {filters(forms).map((prop, idx) => (
      <Filterable
        source={prop.name}
        label={prop.label}
        key={prop.name}
        prop={{ ...prop, value: props.filterValues[prop.name] || "" }}
      />
    ))}
  </Filter>
);
export default ExplorerFilters;
