import React, { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import classnames from "classnames";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { getListControllerProps } from "ra-core";
import {
  Title,
  ListToolbar,
  BulkDeleteButton,
  BulkActionsToolbar,
  ListActions as DefaultActions,
  Pagination as DefaultPagination
} from "react-admin";

const DefaultBulkActionButtons = props => <BulkDeleteButton {...props} />;

export const styles = createStyles({
  root: {
    display: "flex"
  },
  card: {
    position: "relative",
    flex: "1 1 auto"
  },
  actions: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "flex-start"
  },
  noResults: { padding: 20 }
});

const sanitizeRestProps = ({
  actions,
  basePath,
  bulkActions,
  changeListParams,
  children,
  classes,
  className,
  crudGetList,
  currentSort,
  data,
  defaultTitle,
  displayedFilters,
  exporter,
  filter,
  filterDefaultValues,
  filters,
  filterValues,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  hideFilter,
  history,
  ids,
  isLoading,
  loadedOnce,
  locale,
  location,
  match,
  onSelect,
  onToggleItem,
  onUnselectItems,
  options,
  page,
  pagination,
  params,
  permissions,
  perPage,
  push,
  query,
  refresh,
  resource,
  selectedIds,
  setFilters,
  setPage,
  setPerPage,
  setSelectedIds,
  setSort,
  showFilter,
  sort,
  theme,
  title,
  toggleItem,
  total,
  translate,
  version,
  ...rest
}) => rest;

export const ListView = withStyles(styles)(
  ({
    actions,
    aside,
    filter,
    filters,
    bulkActions,
    bulkActionButtons,
    pagination,
    children,
    className,
    classes,
    exporter,
    title,
    ...rest
  }) => {
    const { defaultTitle, version } = rest;
    const controllerProps = getListControllerProps(rest);
    return (
      <div
        className={classnames("list-page", classes.root, className)}
        {...sanitizeRestProps(rest)}
      >
        <Title title={title} defaultTitle={defaultTitle} />
        <Card className={classes.card}>
          {bulkActions !== false &&
            bulkActionButtons !== false &&
            bulkActionButtons &&
            !bulkActions && (
              <BulkActionsToolbar
                {...controllerProps}
                ids={controllerProps.ids.filter(
                  id =>
                    id &&
                    id !== null &&
                    typeof id.indexOf === "function" &&
                    id.indexOf("/") !== -1
                )}
                selectedIds={controllerProps.selectedIds.filter(
                  id =>
                    id &&
                    id !== null &&
                    typeof id.indexOf === "function" &&
                    id.indexOf("/") !== -1
                )}
              >
                {bulkActionButtons}
              </BulkActionsToolbar>
            )}
          {(filters || actions) && (
            <ListToolbar
              filters={filters}
              {...controllerProps}
              actions={actions}
              bulkActions={bulkActions}
              exporter={exporter}
              permanentFilter={filter}
            />
          )}
          <div key={version}>
            {children &&
              cloneElement(Children.only(children), {
                ...controllerProps,
                hasBulkActions:
                  bulkActions !== false && bulkActionButtons !== false
              })}
            {pagination && cloneElement(pagination, controllerProps)}
          </div>
        </Card>
        {aside && cloneElement(aside, controllerProps)}
      </div>
    );
  }
);

ListView.propTypes = {
  actions: PropTypes.element,
  aside: PropTypes.node,
  basePath: PropTypes.string,
  bulkActions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  bulkActionButtons: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  children: PropTypes.element,
  className: PropTypes.string,
  classes: PropTypes.object,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string
  }),
  data: PropTypes.object,
  defaultTitle: PropTypes.string,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  hideFilter: PropTypes.func,
  ids: PropTypes.array,
  isLoading: PropTypes.bool,
  onSelect: PropTypes.func,
  onToggleItem: PropTypes.func,
  onUnselectItems: PropTypes.func,
  page: PropTypes.number,
  pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  perPage: PropTypes.number,
  refresh: PropTypes.func,
  resource: PropTypes.string,
  selectedIds: PropTypes.array,
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  setSort: PropTypes.func,
  showFilter: PropTypes.func,
  title: PropTypes.any,
  total: PropTypes.number,
  translate: PropTypes.func,
  version: PropTypes.number
};

ListView.defaultProps = {
  actions: <DefaultActions />,
  classes: {},
  bulkActionButtons: <DefaultBulkActionButtons />,
  pagination: <DefaultPagination />
};
