import React, { Fragment } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import SaveIcon from "@material-ui/icons/Save";
import { Toolbar } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { translate } from "react-admin";
import compose from "recompose/compose";
import Button from "@material-ui/core/Button";
import { API_URL } from "../config";

const styles = theme => ({
  toolbar: {
    position: "fixed",
    bottom: 0,
    width: "100%"
  },
  button: {
    marginRight: theme.spacing.unit * 1
  }
});

// Perché un Fragment?
// Se non inserisco un wrapper il sistema prova a iniettare proprietà che non esistono.
const ViewToolbar = ({ translate, classes, record, ...props }) => (
  <Toolbar className={classes.toolbar}>
    <Fragment>
      {record && record.perms && record.perms.can_download && (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          href={`${API_URL}/explorer/${record.record_id}/${
            record.type
          }/download?token=${localStorage.getItem("token")}`}
        >
          <GetAppIcon />
          {translate("resources.explorer.actions.download")}
        </Button>
      )}
      {record && record.perms && record.perms.can_edit && (
        <Button
          variant="contained"
          color="secondary"
          href={`#/explorer/${record.record_id}/${record.type}s`}
          className={classes.button}
        >
          <SaveIcon />
          {translate("resources.explorer.actions.edit")}
        </Button>
      )}
    </Fragment>
  </Toolbar>
);
export default compose(translate, withStyles(styles))(ViewToolbar);
