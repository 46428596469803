import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Link from "../components/Link";

const styles = {
  link: {
    cursor: "pointer"
  }
};

const NavEye = ({ classes, record, onClick }) =>
  (record.id !== "prev" && (
    <Link
      variant="body1"
      className={classes.link}
      onClick={e => !e.preventDefault() && onClick(record)}
    >
      <VisibilityIcon />
    </Link>
  )) ||
  null;

export default compose(withStyles(styles))(NavEye);
