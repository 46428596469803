import React from "react";

import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import FolderIcon from "@material-ui/icons/Folder";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import HelpIcon from "@material-ui/icons/Help";
const NavIcon = ({ record }) => {
  if (record.id === "prev") {
    return <ArrowUpwardIcon style={{ marginTop: 10, marginBottom: 10 }} />;
  } else if (record.type === "container") {
    return <FolderIcon />;
  } else if (record.type === "asset") {
    return <InsertDriveFileIcon />;
  } else {
    return <HelpIcon />;
  }
};
export default NavIcon;
