import { API_URL } from "./config";
import { fetchUtils } from "ra-core";
import getHeaders from "./authHeaders";

export const RESTORE = "RECYCLE_RESTORE";
const addRecyclerFeature = requestHandler => (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  if (type === RESTORE) {
    const url = `${API_URL}/recycler/${params.id}/restore`;

    return fetchJson(url, {
      method: "PATCH",
      headers: getHeaders()
    }).then(response => ({
      data: response.json
    }));
  }
  return requestHandler(type, resource, params);
};

export default addRecyclerFeature;
